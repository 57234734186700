<template>
  <section class="app-footer">
    <footer class="footer">
      <div>
        <div class="row footer-container d-flex justify-content-center align-items-center" >
              
          <div class="col-md-3 col-sm-12 mb-4 text-center">
            <img class="footer-logo"  src="@/assets/images/footer-logo.png" alt="logo" />
          </div>
          <div class="col-md-3 col-sm-12 text-sm-center text-md-left">
            <h3>
              Liens utiles
            </h3>
            <ul class="pl-md-0">
              <li>Accueil</li>
              <li>Contact</li>
              <li>Mentions légales</li>
              <li>Conditions générales de vente</li>
            </ul>
          </div>
          <div class="col-md-6 col-sm-3 computer">
              <img  class="bird" src="@/assets/images/import/pr/birds1-background.png" alt="footer" />
          </div>
        </div>
        <!-- image vague -->
        <div class="row wave-container" :style="`--scroll:${newscroll}%`">
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'app-footer',
  computed:{

  },
  data() {
    return {
      newscroll:0,
      isenter:""
    }
  },
  watch:{
    newscroll(){
      // window.addEventListener('wheel', function(event) {
          // val
          // this.newscroll = event.deltaY;
          // console.log('newscroll',event.deltaY);
          // if (event.deltaY < 0) {
          //   // val = 100
          //     // console.log("scrolling up")
          // } else if (event.deltaY > 0) {
          //   this.newscroll = event.deltaY
          //     console.log("newscroll",this.newscroll)
          // }
          // return event.deltaY
      // }
      // )
    }
  },
  methods:{
    handleScroll(e){
      // console.log(e.deltaY)
      // if (e.deltaY) {
        if (e.deltaY>0) {
          this.newscroll = this.newscroll +15
        }else{
          this.newscroll = this.newscroll - 15
        }
        // this.isenter = setTimeout(this.handleScroll ,2000)
      // }
    }
  },
  mounted() {
    // this.newscroll = 100
     window.addEventListener('wheel', this.handleScroll
      // function(event) {
          // this.newscroll = event.deltaY;
          // console.log('newscroll',event.deltaY);
          // if (event.deltaY) {
          //   this.schange()
          // }
          // if (event.deltaY < 0) {
          //   // val = 100
          //     // console.log("scrolling up")
          // } else if (event.deltaY > 0) {
          //   this.newscroll = event.deltaY
          //     console.log("newscroll",this.newscroll)
          // }
          // return event.deltaY
      // }
      )
  },
}
</script>

<style scoped>
.app-footer{
  z-index: 44;
  margin-top: 6px !important;
}
.footer-container{
    max-width: 1400px;
    margin: auto;

}
.footer {
  background: #fff;
  border-top: 2px solid #ff9a1c;
  padding-bottom: 0px;
  margin-top: 6px !important;
}

@media screen and (max-width: 992px) {
  .footer-logo {
    height: 100px;
    width: auto;
  }
}
@media screen and (min-width: 992px) {
  .footer-logo{
    height: 200px;
    width:auto ;
  }
}

.wave{
  width: 100%;
}
.bird{
  margin-top: -75px;
  height: 250px;
  width:auto ;
}
.wave-container{
  background-image: url('../../assets/images/import/pr/Wave-2.png');
  margin-bottom: -50px;
  height: 8.5rem;
  background-repeat: repeat-x;
  background-position: left var(--scroll) bottom 0%;
  transition:  1s linear  ;
}
/* .wave-container{
  background-position: right var(--scroll) top 0%;
} */
/* @keyframes animatedBackground {
  from {
    background-position: 50% 100%;
  }
  to {
    background-position: 25% 100%;
  }
} */
</style>