import Vue from 'vue'
import App from './App.vue'
import router from './router/pr'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import ProductZoomer from 'vue-product-zoomer'
import store from './store'
import checkView from 'vue-check-view'
import Vuelidate from 'vuelidate'

import AOS from 'aos'
import 'aos/dist/aos.css';
import device from "vue-device-detector"



Vue.use(ProductZoomer)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(checkView)
Vue.use(Vuelidate)
Vue.use(device)

AOS.init()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
